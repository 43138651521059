.block_welcome {
	text-align: center;
	background-color: theme(primary, base);
	color: $white;

	@include media-breakpoint-up(md) {
		.wrap > *:last-child { margin-bottom: 0; }
		.welcome_title { text-align: right; }
		.welcome_content { text-align: left; }
	}

	@include media-breakpoint-up(lg) {
		border-top: 3px solid $white;
		box-shadow: 0 -5px 8px 0 rgba(0,0,0,0.3);		
	}

}

	.welcome-title {
		font-style: italic;
		font-weight: 800;
		letter-spacing: -0.0375em;
		text-transform: uppercase;
		span {
			font-size: 80%;
			line-height: 1em;
			display: block;
		}
	}

	.welcome_content {
		> *:last-child { margin-bottom: 0; }
	}


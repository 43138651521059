$form_focus_border: 			$yellow;
$form_focus_bg: 				lighten($yellow, 40%);

.form {
	font-size: 1rem;
	font-family: $font-family-sans-serif;
}

	//.form_security { overflow: hidden; margin-bottom: 1em; }

	.form_title {
		margin: 0 0 0.5em;
		font-size: 1.4rem;
		font-weight: 800;
		small {
			display: block;
			font-size: 1rem;
			font-weight: 400;
			letter-spacing: 0;
			text-transform: uppercase;
		}
	}

	.form_group {
		//padding: 5px 17px;
		&.form_hp { display: none; }
		&.form_spam { margin: 0 0 5px; }
	}

		.form_error {
			border: 1px solid $color-danger;
			background-color: lighten($color-danger, 40%);
		}

		.error-message {
			margin: -1px 0 0 3px;
			padding: 0 0 2px !important;
			font-size: 12px;
			font-weight: 600;
			line-height: 1em;
			letter-spacing: -0.0275em;
			text-transform: uppercase;
			color: darken($color-danger, 5%);
		}

		.form-control {
			display: block;
			width: 100%;
			margin: 0 0 5px;
			padding: 10px 8px;
			font-size: 1rem;
			border: 1px solid #ddd;
			outline: none;
			background-color: $white;
			&:focus {
				border-color: $form_focus_border;
				background-color: $form_focus_bg;
			}
		}

		textarea.form_control { min-height: 120px; }



/*	.form_spam {
		@include media-breakpoint-down(xs) {
			position: relative;
			overflow: hidden;
			height: 75px;
			.g-recaptcha {
				@include transform(scale(0.8));
				position: absolute;
				top: 0;
				left: -18px;
			}
		}
	}*/

	//.form_submit { margin-top: 0.25em; }



.form_lr {
	@include media-breakpoint-up(md) {

		.form_group { padding: 0 0.25em 0.1em }

		.form_left,
		.form_right { width: 50%; }

		.form_left { float: left; clear: both; }
		
		.form_right { float: right; }
		
		.form_clear { clear: both; }

		.form_state { width: 20%; clear: none; }
		.form_zip { width: 30%; }

	}
}

.form_lr--sm {
	@include media-breakpoint-only(md) {

		.form_group { padding: 0 0.25em 0.1em }

		.form_left,
		.form_right { width: 50%; }
		
		.form_left { float: left; }
		
		.form_right { float: right; }
		
		.form_clear { clear: both; }

	}
}

.hidden { display: none !important }

.hidden--xs { @include media-breakpoint-down(xs) { display: none !important; } }
.hidden--sm { @include media-breakpoint-only(sm) { display: none !important; } }
.hidden--md { @include media-breakpoint-only(md) { display: none !important; } }
.hidden--lg { @include media-breakpoint-only(lg) { display: none !important; } }
.hidden--xl { @include media-breakpoint-up(xl) { display: none !important; } }

[class*="visible-"] { display: none; }
.visible--xs { @include media-breakpoint-down(xs) { display: block !important; } }
.visible--sm { @include media-breakpoint-only(sm) { display: block !important; } }
.visible--md { @include media-breakpoint-only(md) { display: block !important; } }
.visible--lg { @include media-breakpoint-only(lg) { display: block !important; } }
.visible--xl { @include media-breakpoint-up(xl) { display: block !important; } }

.db { display: block; }
.mt0 { margin-top: 0 !important; }
.mb0 { margin-bottom: 0 !important; }
.mt30 { margin-top: 30px !important; }
.mt15 { margin-top: 15px !important; }
.mb30 { margin-bottom: 30px !important; }
.mb15 { margin-bottom: 15px !important; }

.notrfm { transform: none !important; }

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.ff--sans { font-family: $font-family-sans-serif !important; }
.ff--serif { font-family: $font-family-serif !important; }
.ff--monospace { font-family: $font-family-monospace !important; }

.txt--up { text-transform: uppercase !important; }
.txt--cap { text-transform: capitalize !important; }
.txt--lg { font-size: 115% !important; }
.txt--md { font-size: inherit !important; }
.txt--sm { font-size: 85% !important; }
.lh1 { line-height: 1.1em !important; }
.lh125 { line-height: 1.25em !important; }
.txt--left { text-align: left !important; }
.txt--right { text-align: right !important; }
.txt--center { text-align: center !important; }

/*.b0 { font-weight: $font-weight-normal !important; } }
.b1 { font-weight: $font-weight-bold !important; } }
.b2 { font-weight: $font-weight-bolder !important; } }*/

.color--text { color: $color-text; }
.color--white { color: $white; }
.color--primary { color: $color-primary; }
.color--secondary { color: $color-secondary; }
.color--highlight { color: $color-highlight; }
.color--accent { color: $color-accent; }
.color--link { color: $color-link; }
.color--review { color: $color-review; }
.color--success { color: $color-success; }
.color--danger { color: $color-danger; }
.color--warning { color: $color-warning; }
.color--info { color: $color-info; }

.bg--text { background-color: $color-text; }
.bg--white { background-color: $white; }
.bg--primary { background-color: $color-primary; }
.bg--secondary { background-color: $color-secondary; }
.bg--highlight { background-color: $color-highlight; }
.bg--link { background-color: $color-link; }
.bg--review { background-color: $color-review; }
.bg--success { background-color: $color-success; }
.bg--danger { background-color: $color-danger; }
.bg--warning { background-color: $color-warning; }
.bg--info { background-color: $color-info; }

.gdfancybg--text { @include gdfancy-bg($color-text); }
.gdfancybg--white { @include gdfancy-bg($white); }
.gdfancybg--primary { @include gdfancy-bg($color-primary); }
.gdfancybg--secondary { @include gdfancy-bg($color-secondary); }
.gdfancybg--highlight { @include gdfancy-bg($color-highlight); }
.gdfancybg--review { @include gdfancy-bg($color-review); }
.gdfancybg--success { @include gdfancy-bg($color-success); }
.gdfancybg--danger { @include gdfancy-bg($color-danger); }
.gdfancybg--warning { @include gdfancy-bg($color-warning); }
.gdfancybg--info { @include gdfancy-bg($color-info); }
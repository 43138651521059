.site_banner {

	padding: 2.5em 0;
	text-align: center;
	border-top: 10px solid theme(secondary, base);
	border-bottom: 5px solid #ccc;
	background-color: $white;
	box-shadow: 0 5px 8px 0 rgba(0,0,0,0.3);

	.button-mobile { margin-bottom: 0; text-align: left; }
	.logo-banner { margin: 0 auto; }
	.contact-call { margin: 0; }

	@include media-breakpoint-only(md) {}

	@include media-breakpoint-up(md) {}

	@include media-breakpoint-up(lg) {}

}
.main {
	.form_quote {
		@include decard(0, transparent);
	}
}

	
.company_form {
	@extend %card;
	padding: 20px;
	margin-bottom: 1em;
	@include media-breakpoint-up(lg) {
		float: right;
		width: 298px;
		margin: 0 0 10px 10px;
	}
	@include media-breakpoint-up(xl) {
		width: 345px;
	}
}

.company_coverage {

	.coverage_title {
		font-size: 1.1rem;
		line-height: 1.325em;
	}

	.coverage_city {
		font-size: 0.9rem;
		line-height: 1.325em;
	}

}
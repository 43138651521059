.sidebar {



}


	.company_block {

		.company_logo {
			width: 100%;
			margin: 0 auto 15px;
		}

		.company_contact {
			text-align: center;
			.phone {
				font-size: 28px;
				font-weight: 800;
				letter-spacing: -0.0325em;
				line-height: 1em;
			}
		}

		.company_address {
			font-size: 0.9rem;
			line-height: 1em;
			text-align: center;
			.address_title {
				display: block;
				margin-bottom: 5px;
				font-size: 1.3rem;
				line-height: 1em;
				small { display: block; }
			}
		}

		.list-company {
			font-weight: 600;
			text-align: center;
			li { padding-left: 1.5em; }
		} 

		@include media-breakpoint-only(md) {
			.company_wrapper {
				display: flex;
				align-items: center;
			}
			.company_media {
				padding-right: 15px;
				margin-right: 15px;
				border-right: 1px solid #ccc;
				.company_logo { margin-bottom: 0; }
			}
			.company_extra {
				clear: both;
				margin-top: 2em;
				.list-company {
					br { display: none; }
					li { margin-right: 10px; }
				}
			}
		}

	}
.ad-box{
	max-width:800px;
	margin:0 auto 20px;
	padding:20px;
	text-align:center;
	border:1px solid #ccc;
	background-color:#fff;

	img {
		display:block;
		width:100%;
		max-width:400px;
		margin:0 auto;
		vertical-align:middle;
	}

	address { 
		display:block;
		margin:10px 0 0;
		font-size:14px;
		line-height:1em;
	}

	.call {
		margin:0;
		font-size:17px;
		line-height:1em;
		
		strong {
			display:block;
			margin:15px 0 0;
			line-height:1em;
		}

		.phone {
			font-size:36px;
			font-weight:800;
			line-height:1em;
			letter-spacing:-3px;
			color:#5fa3d3;
		}

	}

	@include media-breakpoint-up(md) {

		overflow: hidden;
		text-align: left;
		
		.ad-head{
			float:left;
			width:360px;
			padding-right:30px;
		}
		
		.ad-foot { float:left }

		.call strong { margin-top:0; }

	}

}

.abox {
	max-width: 360px;
	margin: 0 auto 20px;
	padding: 15px;
	@include font-size(14);
	text-align: center;
	line-height: 1em;
	border: 1px solid #ccc;
	background-color: $white;

	img {
		display: block;
		width: 100%;
		margin: 0 auto 8px;
	}

	a.ab-logo {
		display: block;
		margin: 0 auto 15px;
		font-weight: $font-weight-bold;
		color: theme(primary, base) !important;
	}

	p { margin-bottom: 8px; }

	p.call {
		.phone {
			@include font-size(24);
			font-weight: $font-weight-bolder;
			color: $color-text;
		}
	}

	p:not(.call) {
		line-height: 1.325em;
	}
 
}



.list-companies-free {

	p {
		margin: 0;
		padding: 15px;
		&:nth-child(even) {
			background-color: #fff;
		}
	}

	strong, span {
		display: block;
		font-size: 115%;
		font-weight: $font-weight-bold;
	}

	@include media-breakpoint-up(md) {
		p { position: relative; }
		span {
			position: absolute;
			right: 15px; top: 27px;
		}
	}

}


.sidebox {
	max-width: 360px;
	margin: 30px auto;
	padding: 30px;
	font-size: 16px;
	line-height: 1;
	text-align: center;
	border: 1px solid #ccc;
	background-color: white;
	img {
		display: block;
		margin: 0 auto 15px;
		max-width: 100%;
	}
	.phone {
		font-size: 32px;
		font-weight: $font-weight-bolder;
	}
	h3 { margin: 0 0 5px; }
	p {
		margin-bottom: 5px;
	}
	p.lead strong { font-weight: $font-weight-bolder; letter-spacing: -0.0575em; }
	p:last-of-type { margin-bottom: 0; }
	hr { margin: 10px 0; }
}

.sidebar {
	@include media-breakpoint-up(lg) {
		> *:first-child { margin-top: 0; }
	}
}
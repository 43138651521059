.reviews_slider {

	.slick-prev,
	.slick-next {
		width: 44px;
		background-color: $white;
		background-size: 35%;
		opacity: 1;
		&:hover {
			background-color: #d9d9d9;
			opacity: 1;
		}
	}

	.slick-prev { 
	    left: 0;
	    border-radius: 6px 0 0 6px;
		background-image: url("/_/images/icons/angle-left-review.png");
	}
	.slick-next {
		right: 0;
		border-radius: 0 6px 6px 0;
		background-image: url("/_/images/icons/angle-right-review.png");
	}

/*	@include media-breakpoint-up(lg) {

		.slick-prev,
		.slick-next {
			width: 44px;
			background-size: 40%;
		}

	}*/

}
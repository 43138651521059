.coverage {
	@extend %card;
}

	.coverage-title {
		margin: 0 0 0.75rem;
		font-size: 1rem;
		font-weight: 800;
		text-transform: uppercase;
	}

	.coverage-list { 
		margin-bottom: 0.5rem;
		line-height: 1.4em;
		&:last-of-type { margin-bottom: 0; }
	}

		.coverage-county {
			font-size: 1rem;
			font-weight: 600;
		}

		.coverage-city {
			font-size: 0.875rem;
		}
html {
	font-size: $font-size-sm;
	line-height: $line-height-base;
	-webkit-text-size-adjust: 100%;

	@include media-breakpoint-up(md) {
		font-size: $font-size-md;
	}

	@include media-breakpoint-up(lg) {
		font-size: $font-size-lg;
	}

	@include media-breakpoint-up(xl) {
		font-size: $font-size-xl;
	}


}

body {
	font-size: 1rem;
	font-family: $font-family-body;
}

%heading {
	margin: 1.5em 0 0.75em;
	line-height: 1em;
	small { display: block; }
}

@mixin heading($sm, $md: 1.065, $lg: 1.10, $xl: 1.15) {
	font-size: $sm * 1rem;
	@include media-breakpoint-up(md) { font-size: $sm * $md * 1rem; }
	@include media-breakpoint-up(lg) { font-size: $sm * $lg * 1rem; }
	@include media-breakpoint-up(xl) { font-size: $sm * $xl * 1rem; }	
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	@extend %heading;
	font-weight: $font-weight-bold;
}

h6, .h6, %h6 {
	@include heading(1.05);
}

h5, .h5, %h5 {
	@include heading(1.175);
	letter-spacing: -0.0325em;
}

h4, .h4, %h4 {
	@include heading(1.345);
	letter-spacing: -0.0325em;
}

h3, .h3, %h3 {
	@include heading(1.497);
	font-weight: $font-weight-bolder;
	letter-spacing: -0.035em;
}

h2, .h2, %h2 {
	@include heading(1.685);
	letter-spacing: -0.035em;
}

h1, .h1, %h1 {
	@include heading(1.855, 1.075, 1.15, 1.175);
	font-weight: $font-weight-bolder;
	letter-spacing: -0.0375em;
	text-transform: uppercase;
}

.font--lg, %font--lg { font-size: 115%; }
	@mixin font--lg { font-size: 115%; }

.font--md, %font--md { font-size: 100%; }
	@mixin font--md { font-size: 100%; }

.font--sm, %font--sm { font-size: 85%; }
	@mixin font--sm { font-size: 85%; }

.lh--sm, %lh--sm { line-height: $line-height-sm; }
	@mixin lh--sm { line-height: $line-height-sm; }
.lh--md, %lh--md { line-height: $line-height-md; }
	@mixin lh--md { line-height: $line-height-md; }
.lh--lg, %lh--lg { line-height: $line-height-lg; }
	@mixin lh--lg { line-height: $line-height-lg; }

blockquote {
	margin: 1em 0 1em 2em;
	padding: 1em;
	@include font--lg;

	border-left: 5px solid;
	p { margin-bottom: 0.5em; }
	> *:last-child { margin-bottom: 0; }
	.bq--title {
		display: block;
		font-size: 1.325rem;
		font-weight: 800;
		letter-spacing: -0.0275em;
		text-transform: uppercase;
	}
	&.bq-cite {
		border: 1px solid #ccc;
		border-left: none;
		border-right: none;		
	}
	&.bq-text { border-color: $color-text; .bq--title { color: $color-text } }
	&.bq-primary { border-color: $color-primary; .bq--title { color: $color-primary } }
	&.bq-secondary { border-color: $color-secondary; .bq--title { color: $color-secondary } }
	&.bq-highlight { border-color: $color-highlight; .bq--title { color: $color-highlight } }
	&.bq-accent { border-color: $color-accent; .bq--title { color: $color-accent } }
	&.bq-success { border-color: $color-success; .bq--title { color: $color-success } }
	&.bq-danger { border-color: $color-danger; .bq--title { color: $color-danger } }
	&.bq-warning { border-color: $color-warning; .bq--title { color: $color-warning } }
	&.bq-info { border-color: $color-info; .bq--title { color: $color-info } }
	&.review {
		padding: 0;
		margin: 0 0 1em;
		font-size: 1rem;
		border: none;
	}
}

address {
	margin-bottom: 1em;
	font-style: normal;
	.address-company,
	.address-street { display: block; }
}


p {
	margin: 0 0 1em;
	&.lead {
		@include font--lg;
		line-height: $line-height-lg;
	}
	&.note {
		@include font--sm;
		line-height: $line-height-sm;
	}
}


hr {
	display: block;
	width: 100%;
	height: 1px;
	margin: 2em 0;
	border: none;
	background-color: #333;
	&.hr-inset {
		margin: 1.5em auto;
		border: none;
		background-color: #bbb;
		box-shadow: 0 1px 0 0 #fff;
	}
}

abbr, abbr[title] {
	border-bottom: 1px dotted theme(text, base);
	text-decoration: none;
}

code, data,
tt, kbd, samp { font-family: $font-family-monospace; }

mark { background-color: $yellow; }

b, strong, .b { font-weight: $font-weight-bold; }
.bolder { font-weight: $font-weight-bolder; }

// =====================================================
// VARIABLES - TOC
// -----------------------------------------------------
//
// 1. Grid - Bootstrap Grid Maps
//
// 2. Layout - zindex, position, padding, margin, radius, etc.
//
// 3. Typography - Font Styles
//
// 4. Colors - Universal Colors
//
// =====================================================




// =====================================================
// 1. GRID
// =====================================================
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px
) !default;

$container-max-widths: (
  	sm: 540px,
  	md: 720px,
  	lg: 960px,
  	xl: 1140px
) !default;

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;




// =====================================================
// 2. LAYOUT VARIABLES
// =====================================================
$margin-sm: 	0.5em !default;
$margin-md: 	1em !default;
$margin-lg: 	2em !default;

$padding-sm: 	5px !default;
$padding-md: 	15px !default;
$padding-lg: 	30px !default;

$radius-sm: 	3px !default;
$radius-md: 	4px !default;
$radius-lg: 	6px !default;




// =====================================================
// 4. COLORS
// =====================================================
$white:  #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:  #000 !default;

$blue:    	#007bff !default;
$indigo:  	#6610f2 !default;
$purple:  	#6f42c1 !default;
$pink:    	#e83e8c !default;
$red:     	#dc3545 !default;
$orange:  	#fd7e14 !default;
$yellow:  	#ffd507; //#ffc107 !default;
$green:   	#28a745 !default;
$teal:    	#20c997 !default;
$cyan:    	#17a2b8 !default;
$maroon: 	#8b1b2c !default;
$gold: 		#786a4b !default;

$color-primary: 		#f7931e; // ORANGE
$color-secondary: 		#333; // GRAY
$color-highlight: 		#0e76bc; // LIGHT BLUE
$color-accent: 			$orange; // ORANGE;
$color-review: 			#f90; //$orange !default;
$color-success: 		$green !default;
$color-danger: 			$red !default;
$color-info: 			$indigo !default;
$color-warning: 		$yellow !default;

$color-text:			$gray-700;
$color-link: 			$cyan;
$color-link-hover: 		lighten($color-link, 10%);
$color-link-visited: 	$purple;
$body-bg:       		#f0f0f0;
$color-phone: 			$color-primary;

// THEME COLOR DECLARATIONS
// -------------------------------------------

// THEME MAP - use function: theme(key, variant)
// ex: h1 { color: theme(primary, dark); }
// OR: div { background-color: theme(success, opac); }

// COLOR MODIFIES
$color-lighten: 10% !default;
$color-darken: 10% !default;
$color-opac: 0.5 !default;

$theme: (
	primary: (
		base: $color-primary,
		light: lighten($color-primary, $color-lighten),
		dark: darken($color-primary, $color-darken),
		opac: transparentize($color-primary, $color-opac)
	),
	secondary: (
		base: $color-secondary,
		light: lighten($color-secondary, $color-lighten),
		dark: darken($color-secondary, $color-darken),
		opac: transparentize($color-secondary, $color-opac)
	),
	highlight: (
		base: $color-highlight,
		light: lighten($color-highlight, $color-lighten),
		dark: darken($color-highlight, $color-darken),
		opac: transparentize($color-highlight, $color-opac)
	),
	accent: (
		base: $color-accent,
		light: lighten($color-accent, $color-lighten),
		dark: darken($color-accent, $color-darken),
		opac: transparentize($color-accent, $color-opac)
	),
	review: (
		base: $color-review,
		light: lighten($color-review, $color-lighten),
		dark: darken($color-review, $color-darken),
		opac: transparentize($color-review, $color-opac)
	),
	success: (
		base: $color-success,
		light: lighten($color-success, $color-lighten),
		dark: darken($color-success, $color-darken),
		opac: transparentize($color-success, $color-opac)
	),
	danger: (
		base: $color-danger,
		light: lighten($color-danger, $color-lighten),
		dark: darken($color-danger, $color-darken),
		opac: transparentize($color-danger, $color-opac)
	),
	warning: (
		base: $color-warning,
		light: lighten($color-warning, $color-lighten),
		dark: darken($color-warning, $color-darken),
		opac: transparentize($color-warning, $color-opac)
	),
	info: (
		base: $color-info,
		light: lighten($color-info, $color-lighten),
		dark: darken($color-info, $color-darken),
		opac: transparentize($color-info, $color-opac)
	),
	text: (
		base: $color-text,
		light: lighten($color-text, $color-lighten),
		dark: darken($color-text, $color-darken),
		opac: transparentize($color-text, $color-opac)
	),
	link: (
		base: $color-link,
		light: lighten($color-link, $color-lighten),
		dark: darken($color-link, $color-darken),
		opac: transparentize($color-link, $color-opac)
	)
) !default;

// These variable's values should be equal to
// something listed above - a THEME COLOR VARIABLE.






// ===========================================
// 4. TYPOGRAPHY
// ===========================================

$font-family-sans-serif:  			"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:       			"Times New Roman", Georgia, Times, serif;
$font-family-monospace:   			Menlo, Monaco, Consolas, "Courier New", monospace;

$font-family-body:					$font-family-sans-serif;
$font-family-heading:				$font-family-serif;
$font-family-mobile: 				"Helvetica Neue", Helvetica, Arial, sans-serif;

$font-size-sm:						15px;
$font-size-md:						16px;
$font-size-lg:						17px;
$font-size-xl:						17px;
$line-height-min:					1.1em;
$line-height-sm: 					1.525em;
$line-height-md: 					1.575em;
$line-height-lg: 					1.625em;
$line-height-max:					1.775em;

$line-height-base:					$line-height-md;
$line-height-heading:				$line-height-min;

$font-weight-normal:				400;
$font-weight-bold:					600;
$font-weight-bolder:				800;


body {

	min-width: 380px;
	background-color: $body-bg;
	color: theme(text, base);

/*	&:not(.page_front) {
			
		@include media-breakpoint-up(md) {

			height: 100%;
			padding-bottom: 78px;

			.site_info {
				position: absolute;
				bottom: 0;
				width: 100%;
			}

		}
		
	}*/


}
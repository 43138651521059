.logo {
	display: block;
	width: 100%;
	text-decoration: none;
	img {
		display: block;
		width: 100%;
	}
}

	.logo-banner {
		max-width: 320px;
	}

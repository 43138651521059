.menu_states {

	border-top: 1px solid #ccc;
	background-color: $white;
	padding: 2em 0;
	text-align: center;
	box-shadow: none;

	.nav-menu {

		.menu-item {
			display: inline-block;
			font-size: 14px;
			line-height: 1em;
			margin: 3px;

			&.active .menu-link {
				background-color: theme(primary, base);
				&:hover {
					background-color: theme(primary, base);
					cursor: default;
				}
			}

		}

			.menu-link {
				display: block;
				padding: 6px 7px;
				border-radius: 3px;
				background-color: theme(link, base);
				color: $white;
				&:hover { background-color: theme(link, light); }
			}

			.menu-link[href*="#"] {
				background-color: $gray-400;
				color: theme(text, base);
				&:hover {
					background-color: $gray-400;
					color: theme(text, base);
					cursor: default;
				}
			}

	}


}
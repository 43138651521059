.gallery_slider {
	padding-bottom: 1.5em;

/*	.slick-prev {
		display: none !important;
	}

	.slick-prev,
	.slick-next {
		width: 45px;
		background-color: theme(primary, light);
		background-size: 50%;
		opacity: 1;
		&:hover {
			background-color: theme(primary, dark);
			opacity: 1;
		}
	}*/

	a {
		display: block;
		padding: 0 5px;
		box-shadow: 0 25px 30px -30px rgba(0,0,0,0.8);
		img {
			display: block;
			width: 100%;
			margin: 0;
			padding: 5px 5px 0;
			background-color: $body-bg;
		}
	}

}
$slick-font-path: "/_/fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "/_/images/layout" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff url("/_/images/layout/ajax-loader.gif") center center no-repeat;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    @include transition(all ease-in-out 200ms);
    z-index: 95000;
    position: absolute;
    display: block;
    height: 100%;
    top: 0;
    bottom: 0;
    width: 55px;
    font-size: 0px;
    line-height: 0px;
    cursor: pointer;
    background-color: rgba(black, 0.8);
    background-size: 50%;
    background-position: center center;
    background-repeat: no-repeat;   
    color: transparent;
    padding: 0;
    border: none;
    outline: none;
    opacity: 0;
    &:hover {
        @include transition(all ease-in-out 200ms);
        outline: none;
        opacity: 0.6;
    }
}

.slick-prev {
    left: 0;
    background-image: url("/_/images/icons/angle-left-black.png");
}

.slick-next {
    right: 0;
    background-image: url("/_/images/icons/angle-right-black.png");
}


.block_jumbo {

	padding: 3em 0;

	@include gdfancy-bg(#222);

	@include media-breakpoint-up(md) {
		background: 	url("/_/images/layout/jumbo-lg.jpg"),
						linear-gradient(to bottom, $white 410px, $color-secondary 410px);
		background-size: auto 410px, 100% 100%;
		background-position: top center, center center;
		background-repeat: no-repeat, repeat-x;
	}

	@include media-breakpoint-up(lg) {
		padding: 0;
		background: #333 url("/_/images/layout/jumbo-xl.jpg") top left no-repeat;
		background-size: cover;
		.wrap { padding-top: 2.5em; padding-bottom: 2.5em; }
	}
	
	@include media-breakpoint-up(xl) {
		background-size: 1320px 100%;
		background-position: center center;
		.wrap { padding-top: 3.5em; padding-bottom: 3.5em; }
	}

}

	.jumbo_marketing {

		padding: 0 15px 2em;
		text-align: center;

		//@include media-breakpoint-up(lg) { background-image: none; }

		h1 {
			margin-top: 0;
			font-size: 1.5rem;
			font-weight: 600;
			font-style: italic;
			line-height: 1.325em;
			text-transform: none;
			color: $white;
			b, small {
				display: inline;
				padding: 0.25em 0.75em 0.25em 0.5em;
				box-decoration-break: clone;
			}
			b {
				padding-top: 0.1em;
				font-weight: 800;
				background-color: rgba(theme(primary,base), 0.9);
				box-shadow: 	5px 0 0 rgba(theme(primary,base), 0.9),
								-5px 0 0 rgba(theme(primary,base), 0.9);
			}
			small {
				padding-top: 0.125em;
				font-size: 1.1rem;
				line-height: 1em;
				letter-spacing: 0.0325em;
				background-color: rgba(theme(secondary,base), 0.9);
				box-shadow: 	5px 0 0 rgba(theme(secondary,base), 0.9),
								-5px 0 0 rgba(theme(secondary,base), 0.9);
			}
		}

		ul.marketing-list {
			margin-bottom: 0;
			li {
				padding-left: 1.5em;
				font-weight: 600;
				color: $white;
			}
		}

		@include media-breakpoint-up(sm) {
			h1 {
				font-size: 1.7rem; line-height: 1.25em;
				small { font-size: 1.3rem; line-height: 1em; }
			}
			ul.marketing-list li { font-size: 1.2rem; }
		}

		@include media-breakpoint-up(md) {
			padding-bottom: 2.5em;
			ul.marketing-list li {
				padding: 2px 5px 2px 1.5em;
				background-color: rgba(#eeefe9, 0.8);
				background-position: 5px center;
				color: theme(text, base);
			}
		}

		@include media-breakpoint-up(lg) {
			padding: 0 15px;
			h1 {
				margin-bottom: 0.5em;
				font-size: 2rem; line-height: 1.25em;
				b { padding: 0.1em 0.5em 0.25em 0.25em; }
				small { padding: 0.15em 0.25em 0.15em 0.25em; }
				small { font-size: 1.5rem; line-height: 1em; }
			}
			ul.marketing-list li { font-size: 1.3rem; }
		}

	}

	.jumbo_conversion {

		@include media-breakpoint-only(lg) {
			.form_spam {
				height: 79px;
				.g-recaptcha {
					@include transform(scale(0.945));
					top: -3px;
					left: -9px;
				}
			}
		}
		
		@include media-breakpoint-up(lg) {
			background-color: rgba($white, 0.8);
			.form { @include decard(0 1em, transparent); }
		}

	}
.form_quote {

	@extend %card;

	.form_title {
		text-transform: uppercase;
		small {
 			font-weight: 600;
			color: theme(primary, light);
		}
	}

	.form_group_wrap {
		overflow: hidden;
		> label {
			display: block;
			margin: 0 0 0 5px;
			font-size: 0.95rem;
			font-weight: 600;
		}
		.form_zip_from { float: left; width: 49%; }
		.form_zip_to { float: right; width: 49%; }
	}

	.form_comments textarea { height: 120px; }

	@include media-breakpoint-only(md) {
		.form_left, .form_zip_from { float: left; width: 49.5% !important; }
		.form_right, .form_zip_to { float: right; width: 49.5% !important; }
		.form_clear { clear: both; }
	}

	@include media-breakpoint-only(lg) {
		.form_spam {
			position: relative;
			overflow: hidden;
			height: 65px;
			.g-recaptcha {
				@include transform(scale(0.835));
				position: absolute;
				top: -6px;
				left: -24px;
			}
		}
	}


}
.page_title {
	padding: 1.5em 0;
	text-shadow: 0 1px 2px rgba(0,0,0,0.4);
	border-bottom: 3px solid $white;
	color: $white;
    background: 	linear-gradient( 45deg, rgba(255,255,255,0) 20%, rgba(255,255,255,0.2) 35%, rgba(255,255,255,0) 35% ),
                	linear-gradient( 45deg, rgba(255,255,255,0) 60%, rgba(255,255,255,0.2) 75%, rgba(255,255,255,0) 75% ),
                  	linear-gradient( 135deg, rgba(255,255,255,0) 15%, rgba(255,255,255,0.1) 50%, rgba(255,255,255,0) 90% ),
                  	linear-gradient( to right, theme(accent, dark), theme(accent, light), theme(accent, light), theme(accent, dark) );


	h1 {
		margin: 0;
		padding: 0.25em 0;
		line-height: 1em;
		small {
			color: $white;
		}
	}

	@include media-breakpoint-up(md) {
		padding: 6em 0 1em;
	}

	@include media-breakpoint-up(lg) { padding-top: 8em }

}


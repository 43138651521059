.site_info {

	text-align: center;
	background-color: theme(secondary, base);
	color: $white;

	a { color: $white; }

}

	ul.list-copyright {
		@include list-unstyled;
		@include media-breakpoint-up(md) {
			@include list-inline;
			li { margin-bottom: 0 !important; }
		}
	}
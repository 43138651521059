.review {

	@extend %card;
	border-radius: 6px;

	img.review-stars {
		display: block;
		width: 120px;
		margin: 0 auto 0.75em;
	}

	p { margin-bottom: 0.75em; }

	cite {
		font-size: 1.2rem;
		font-weight: 800;
		text-transform: uppercase;
		color: theme(review, base);
	}

}

$mobile-button-bg: 			transparent;
$toggle-bg: 				theme(primary, light);
$toggle-color: 				$white;
$toggle-hover-bg: 			theme(secondary, light);

.button-mobile {

	padding: 0 15px;
	line-height: 1em;
	background: $mobile-button-bg;

	.mobile_toggle {
		@include transition(all ease-in-out 200ms);
		width: 48px; height: 41px;
		padding: 10px 11px 9px;
		background-color: $toggle-bg;
		border: none;
		border-radius: 4px;
		color: $toggle-color;
		&:hover, &:focus {
			@include transition(all ease-in-out 200ms);
			outline: none;
			background-color: $toggle-hover-bg;
			cursor: pointer;
		}
	}

		.button-bars {
			display: inline-block;
			height: 100%;
			width: 26px;
		}

			.icon-bar {
				@include transition(transform ease-in-out 200ms);
				display: block;
				width: 100%;
				height: 3px;
				border-radius: 4px;
				background-color: $toggle-color;
				&:nth-child(2) { margin: 5px 0; }
			}

	b { display: none; }

	.canvas-slid & {
		.icon-bar {
			&:nth-child(2) { visibility: hidden; }
			&:nth-child(1),
			&:nth-child(3) {
				@include transition(transform ease-in-out 200ms);
			}
			&:nth-child(1) { transform: rotate(45deg); margin-top: 9px; }
			&:nth-child(3) { transform: rotate(-45deg); margin-top: -16px; }
		}
	}

}
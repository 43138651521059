.phone {
	text-decoration: none;
	cursor: default;
	&:hover {
		text-decoration: none;
		outline: none;
		cursor: default;
	}
}

	.phone.mask {
		color: $color-text;
		&:hover {
			outline: none;
			color: $color-text;
		}
	}

	.phone.inherit {
		color: inherit;
		&:hover {
			outline: none;
			color: inherit;
		}
	}

	.contact-call {
		font-size: 1.8rem;
		line-height: 1em;
	}

		.contact-title {
			display: block;
			font-size: 1rem;
			line-height: 1em;
			.contact-call & {
				color: $gray-600;
			}
		}

		.contact-phone {
			letter-spacing: -0.0325em;
			line-height: 1em;
			font-weight: 800;
			.contact-call & {
				color: theme(accent, base);
			}
		}
@mixin btn-reg($bg: $color-link, $color: $white) {
    
    @include transition(all ease-in-out 200ms);
    display: inline-block;
    padding: 12px 20px;
    font-weight: 800;
    font-style: italic;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 3px;
    border: 2px solid darken($bg, 10%);
    background-color: darken($bg, 5%);
    color: $color;
    @include transition(all ease-in-out 200ms);

	&:link, &:visited, &:active {
    	border: 2px solid darken($bg, 12.5%);
    	background-color: darken($bg, 5%);
	    color: $color;
	    @include transition(all ease-in-out 200ms);
	}

    &:hover, &:focus {
        @include transition(all ease-in-out 200ms);
    	border-color: $bg;
        background-color: lighten($bg, 5%);
        color: $color;
    }    

}


.btn {
	@include btn-reg;
    line-height: 1em;
	cursor: pointer;
	small { display: block; line-height: 1em }
	&:focus { outline: 4px solid rgba(0,0,0,0.25); }
}

	%btn-text, .btn-text { @include btn-reg($color-text); }
		@mixin btn-text { @include btn-reg($color-text); }

	%btn-link, .btn-link { @include btn-reg($color-link); }
		@mixin btn-link { @include btn-reg($color-link); }
		
	%btn-primary, .btn-primary { @include btn-reg($color-primary); }
		@mixin btn-primary { @include btn-reg($color-primary); }

	%btn-secondary, .btn-secondary { @include btn-reg($color-secondary); }
		@mixin btn-secondary { @include btn-reg($color-secondary); }
		
	%btn-highlight, .btn-highlight { @include btn-reg($color-highlight); }
		@mixin btn-highlight { @include btn-reg($color-highlight); }
		
	%btn-accent, .btn-accent { @include btn-reg($color-accent); }
		@mixin btn-accent { @include btn-reg($color-accent); }
		
	%btn-success, .btn-success { @include btn-reg($color-success); }
		@mixin btn-success { @include btn-reg($color-success); }
		
	%btn-danger, .btn-danger { @include btn-reg($color-danger); }
		@mixin btn-danger { @include btn-reg($color-danger); }
		
	%btn-info, .btn-info { @include btn-reg($color-info); }
		@mixin btn-info { @include btn-reg($color-info); }
		
	%btn-review, .btn-review { @include btn-reg($color-review); }
		@mixin btn-review { @include btn-reg($color-review); }
		
	%btn-warning, .btn-warning { @include btn-reg($color-warning); }
		@mixin btn-warning { @include btn-reg($color-warning); }

	%btn-block, .btn-block { display: block; text-align: center; }
		@mixin btn-block { display: block; text-align: center; }

	.btn-close {
		float: right;
		margin: 0 0 0 15px;
		padding: 5px;
		font-size: 24px;
		line-height: 1em;
		text-align: center;
		border-radius: 3px;
		border: 1px solid darken($color-danger, 15%);
		background-color: $color-danger;
		color: #fff;
		opacity: 0.8;
		cursor: pointer;
		.close_img {
			width: 0.75rem;
		}
		&:hover {
			background-color: darken($color-danger, 20%);
		}
	}

	%btn--sm, .btn--sm { padding: 8px 12px; font-size: 0.9rem; }
		@mixin btn--sm { padding: 8px 12px; font-size: 0.9rem; }
	%btn--md, .btn--md { padding: 12px 20px; font-size: 1rem; }
		@mixin btn--md { padding: 12px 20px; font-size: 1rem; }
	%btn--lg, .btn--lg { padding: 15px 28px; font-size: 1.1rem; }
		@mixin btn--lg { padding: 15px 28px; font-size: 1.1rem; }

	%btn--wide, .btn--wide { display: block; }
		@mixin btn--wide { display: block; }

	%btn--inline, .btn--inline { display: inline-block; }
		@mixin btn--inline { display: inline-block; }
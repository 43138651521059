ul, ol {
	margin: 0 0 1em 22px;
	padding-left: 0;
	&.list {
		li:not(:last-child) { margin-bottom: 0.5em; }
	}
	&.list-lower-a { list-style-type: lower-alpha; }
	&.list-upper-a { list-style-type: upper-alpha; }
	&.list-circle { list-style-type: circle; }
	&.list-square { list-style-type: square; }
	&.list-decimal { list-style-type: decimal; }
	&.list-roman { list-style-type: lower-roman; }

	ul, ol { margin: 0.5em 0 0.5em 22px; }
	p { margin: 0.5em 0; }
}

ul { list-style-type: disc; }
ul ul { list-style-type: circle; }
ul ul ul { list-style-type: square; }

ol { list-style-type: decimal }
ol ol { list-style-type: upper-alpha; }
ol ol ol { list-style-type: lower-roman; }
ol ol ol ol { list-style-type: lower-alpha; }

dl {
	margin: 0 0 1em;
	dt { font-weight: $font-weight-bold; }
	dd {
		margin-inline-start: 2em;
		&:not(:last-of-type) { margin-bottom: 0.5em; }
	}
}


%list {
	li:not(:last-child) { margin-bottom: 0.25em; }
}

%list-unstyled, ul.list-unstyled {
	margin-left: 0;
	padding-left: 0;
	list-style-type: none;
	list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
	li::before { content: "\200B"; }
}
	@mixin list-unstyled {
		margin-left: 0;
		padding-left: 0;
		list-style-type: none;
		list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
		li::before { content: "\200B"; }		
	}

%list-inline, ul.list-inline {
	@include list-unstyled;
	li { display: inline-block; margin: 0 0.5em 0.5em 0 !important; }
}
	@mixin list-inline {
		@include list-unstyled;
		li { display: inline-block; margin: 0 0.5em 0.5em 0 !important; }
	}

ul.list-icon {
	@extend %list-unstyled;
	margin-left: 0;
	padding-left: 0;
	list-style-type: none;

	&.list-icon-center {
		li { display: inline-block; }
	}

	li {
		padding-left: 2em;
		font-size: 1.125rem;
		background-repeat: no-repeat;
		background-size: 1.25rem;
		background-position: left center;
	}

}



	ul.icon-check li { background-image: url("/_/images/icons/cm_text.svg"); }
	ul.icon-check--primary li { background-image: url("/_/images/icons/cm_primary.svg"); }
	ul.icon-check--secondary li { background-image: url("/_/images/icons/cm_secondary.svg"); }
	ul.icon-check--success li { background-image: url("/_/images/icons/cm_success.svg"); }
	ul.icon-check--accent li { background-image: url("/_/images/icons/cm_accent.svg"); }
	ul.icon-check--highlight li { background-image: url("/_/images/icons/cm_highlight.svg"); }
// ===========================================
// BOOTSTRAP'S GRID
// ===========================================

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);
@include _assert-ascending($container-max-widths, "$container-max-widths");

.container {
	@include make-container();
	@include make-container-max-widths();
}

.container-fluid {
	width: 100%;
	@include make-container();
}

.row {
	@include make-row();
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;
	> .wrap {
		padding-right: 0;
		padding-left: 0;
	}
}


// ===========================================
// BACE GRID
// ===========================================

html {
	@include prefix('box-sizing', 'border-box', webkit moz ms o);
    -ms-overflow-style: scrollbar;
}

	*,
	*:before,
	*:after {

		@include prefix('box-sizing', 'inherit', webkit moz ms o)
		
	}

	.wrap { @include make-col-ready() }

	.grid_wrapper {
		box-sizing: border-box;
		@include make-row();
		margin: 0;

		[class*="col"] {
			@include make-col-ready();
		}

		@include media-breakpoint-up(sm) {
			.col-sm-12 { width: percentage(12/12); }
			.col-sm-11 { width: percentage(11/12); }
			.col-sm-10 { width: percentage(10/12); }
			.col-sm-9 { width: percentage(9/12); }
			.col-sm-8 { width: percentage(8/12); }
			.col-sm-7 { width: percentage(7/12); }
			.col-sm-6 { width: percentage(6/12); }
			.col-sm-5 { width: percentage(5/12); }
			.col-sm-4 { width: percentage(4/12); }
			.col-sm-3 { width: percentage(3/12); }
			.col-sm-2 { width: percentage(2/12); }
			.col-sm-1 { width: percentage(1/12); }
		}
		@include media-breakpoint-up(md) {
			.col-md-break { float: none; width: percentage(12/12); }
			.col-md-12 { float: left; width: percentage(12/12); }
			.col-md-11 { float: left; width: percentage(11/12); }
			.col-md-10 { float: left; width: percentage(10/12); }
			.col-md-9 { float: left; width: percentage(9/12); }
			.col-md-8 { float: left; width: percentage(8/12); }
			.col-md-7 { float: left; width: percentage(7/12); }
			.col-md-6 { float: left; width: percentage(6/12); }
			.col-md-5 { float: left; width: percentage(5/12); }
			.col-md-4 { float: left; width: percentage(4/12); }
			.col-md-3 { float: left; width: percentage(3/12); }
			.col-md-2 { float: left; width: percentage(2/12); }
			.col-md-1 { float: left; width: percentage(1/12); }
		}
		@include media-breakpoint-up(lg) {
			.col-lg-break { float: none; width: percentage(12/12); }
			.col-lg-12 { float: left; width: percentage(12/12); }
			.col-lg-11 { float: left; width: percentage(11/12); }
			.col-lg-10 { float: left; width: percentage(10/12); }
			.col-lg-9 { float: left; width: percentage(9/12); }
			.col-lg-8 { float: left; width: percentage(8/12); }
			.col-lg-7 { float: left; width: percentage(7/12); }
			.col-lg-6 { float: left; width: percentage(6/12); }
			.col-lg-5 { float: left; width: percentage(5/12); }
			.col-lg-4 { float: left; width: percentage(4/12); }
			.col-lg-3 { float: left; width: percentage(3/12); }
			.col-lg-2 { float: left; width: percentage(2/12); }
			.col-lg-1 { float: left; width: percentage(1/12); }
		}	
		@include media-breakpoint-up(xl) {
			.col-xl-break { float: none; width: percentage(12/12); }
			.col-xl-12 { float: left; width: percentage(12/12); }
			.col-xl-11 { float: left; width: percentage(11/12); }
			.col-xl-10 { float: left; width: percentage(10/12); }
			.col-xl-9 { float: left; width: percentage(9/12); }
			.col-xl-8 { float: left; width: percentage(8/12); }
			.col-xl-7 { float: left; width: percentage(7/12); }
			.col-xl-6 { float: left; width: percentage(6/12); }
			.col-xl-5 { float: left; width: percentage(5/12); }
			.col-xl-4 { float: left; width: percentage(4/12); }
			.col-xl-3 { float: left; width: percentage(3/12); }
			.col-xl-2 { float: left; width: percentage(2/12); }
			.col-xl-1 { float: left; width: percentage(1/12); }
		}		
	}


	// ==================================================
	// BLOCKS
	// ==================================================

	.site_banner {
		position: relative;
		z-index: 10;
	}

	.block_jumbo {
		position: relative;
		z-index: 5;
		@include media-breakpoint-up(lg) {
			.row { align-items: center; }
			.jumbo_marketing { @include make-col(8); }
			.jumbo_conversion { @include make-col(4); padding: 0; }
		}
		@include media-breakpoint-up(xl) {
			.jumbo_marketing { @include make-col(8); }
			.jumbo_conversion { @include make-col(4); }
		}
	}

	.block_welcome {
		position: relative;
		z-index: 8;
		@include media-breakpoint-up(md) {
			.row {
				align-items: center; 
				justify-content: center;
			}
			.welcome_title { @include make-col(4); }
			.welcome_content { @include make-col(8); }
		}
		@include media-breakpoint-up(lg) {
			.welcome_title { @include make-col(3); }
			.welcome_content { @include make-col(7); }
		}
		@include media-breakpoint-up(xl) {
			.welcome_content { @include make-col(6); }
		}
	}

	.block_services {
		@include media-breakpoint-up(md) {
			.row { justify-content: center; }
			.service { @include make-col(11); }
		}
		@include media-breakpoint-up(lg) {
			.service { @include make-col(6); }
		}
		@include media-breakpoint-up(xl) {
			//.service { @include make-col(8); }
		}
	}

	.content {
		@include media-breakpoint-up(lg) {
			.main { @include make-col(8); }
			.sidebar { @include make-col(4); }
		}
	}


.block_services {

	background-image: linear-gradient( to bottom, theme(primary, base) 170px, transparent 170px );
	padding-bottom: 4em;
	
	.service_wrap {
		@extend %card;
		padding: 20px;
	}

	.service {
		text-align: center;
		&:not(:last-of-type) { margin-bottom: 2em; }
		h3 {
			margin: 15px 0 10px;
			line-height: 1em;
			letter-spacing: -0.0575em;
			text-transform: uppercase;
			color: theme(primary, base);
		}
		img {
			width: 100%;
			max-width: 120px;
			margin: 0 auto;
		}
	}

	.service_content > *:last-child { margin-bottom: 0; }

	@include media-breakpoint-up(md) {
		padding-top: 0;
		background-image: linear-gradient( to bottom, theme(primary, base) 90px, transparent 90px );
		.service_wrap {
			@include make-row();
			margin-left: 0;
			margin-right: 0;
			text-align: left;
			align-items: center;
			.service_media {
				@include make-col(3);
				padding: 0 15px 0 0;
			}
			.service_content {
				@include make-col(9);
				h3 { margin-top: 0; }
			}
		}
	}

	@include media-breakpoint-up(lg) {
		background-image: linear-gradient( to bottom, theme(primary, base) 140px, transparent 140px );
		.service_wrap {
			.service_media {
				@include make-col(4);
			}
			.service_content {
				@include make-col(8);
			}
		}
	}

	@include media-breakpoint-up(xl) {
		background-image: linear-gradient( to bottom, theme(primary, base) 120px, transparent 120px );
	}

}
%card {
	padding: 1.5em;
	border: 1px solid #ddd;
	background-color: $white;
	box-shadow: 0 1.75em 2em -2em rgba(0,0,0,0.5);
}

@mixin decard($padding: 1.5em, $bg: $white) {
	padding: $padding;
	border: none;
	background-color: $bg;
	box-shadow: none;
}
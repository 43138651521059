@-ms-viewport {
  width: device-width; }

html {
  position: relative;
  min-height: 100%; }

body {
  min-height: 100%;
  margin: 0; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .wrap {
    padding-right: 0;
    padding-left: 0; }

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  -ms-box-sizing: inherit;
  -o-box-sizing: inherit;
  box-sizing: inherit; }

.wrap {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.grid_wrapper {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin: 0; }
  .grid_wrapper [class*="col"] {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px; }
  @media (min-width: 576px) {
    .grid_wrapper .col-sm-12 {
      width: 100%; }
    .grid_wrapper .col-sm-11 {
      width: 91.66667%; }
    .grid_wrapper .col-sm-10 {
      width: 83.33333%; }
    .grid_wrapper .col-sm-9 {
      width: 75%; }
    .grid_wrapper .col-sm-8 {
      width: 66.66667%; }
    .grid_wrapper .col-sm-7 {
      width: 58.33333%; }
    .grid_wrapper .col-sm-6 {
      width: 50%; }
    .grid_wrapper .col-sm-5 {
      width: 41.66667%; }
    .grid_wrapper .col-sm-4 {
      width: 33.33333%; }
    .grid_wrapper .col-sm-3 {
      width: 25%; }
    .grid_wrapper .col-sm-2 {
      width: 16.66667%; }
    .grid_wrapper .col-sm-1 {
      width: 8.33333%; } }
  @media (min-width: 768px) {
    .grid_wrapper .col-md-break {
      float: none;
      width: 100%; }
    .grid_wrapper .col-md-12 {
      float: left;
      width: 100%; }
    .grid_wrapper .col-md-11 {
      float: left;
      width: 91.66667%; }
    .grid_wrapper .col-md-10 {
      float: left;
      width: 83.33333%; }
    .grid_wrapper .col-md-9 {
      float: left;
      width: 75%; }
    .grid_wrapper .col-md-8 {
      float: left;
      width: 66.66667%; }
    .grid_wrapper .col-md-7 {
      float: left;
      width: 58.33333%; }
    .grid_wrapper .col-md-6 {
      float: left;
      width: 50%; }
    .grid_wrapper .col-md-5 {
      float: left;
      width: 41.66667%; }
    .grid_wrapper .col-md-4 {
      float: left;
      width: 33.33333%; }
    .grid_wrapper .col-md-3 {
      float: left;
      width: 25%; }
    .grid_wrapper .col-md-2 {
      float: left;
      width: 16.66667%; }
    .grid_wrapper .col-md-1 {
      float: left;
      width: 8.33333%; } }
  @media (min-width: 992px) {
    .grid_wrapper .col-lg-break {
      float: none;
      width: 100%; }
    .grid_wrapper .col-lg-12 {
      float: left;
      width: 100%; }
    .grid_wrapper .col-lg-11 {
      float: left;
      width: 91.66667%; }
    .grid_wrapper .col-lg-10 {
      float: left;
      width: 83.33333%; }
    .grid_wrapper .col-lg-9 {
      float: left;
      width: 75%; }
    .grid_wrapper .col-lg-8 {
      float: left;
      width: 66.66667%; }
    .grid_wrapper .col-lg-7 {
      float: left;
      width: 58.33333%; }
    .grid_wrapper .col-lg-6 {
      float: left;
      width: 50%; }
    .grid_wrapper .col-lg-5 {
      float: left;
      width: 41.66667%; }
    .grid_wrapper .col-lg-4 {
      float: left;
      width: 33.33333%; }
    .grid_wrapper .col-lg-3 {
      float: left;
      width: 25%; }
    .grid_wrapper .col-lg-2 {
      float: left;
      width: 16.66667%; }
    .grid_wrapper .col-lg-1 {
      float: left;
      width: 8.33333%; } }
  @media (min-width: 1200px) {
    .grid_wrapper .col-xl-break {
      float: none;
      width: 100%; }
    .grid_wrapper .col-xl-12 {
      float: left;
      width: 100%; }
    .grid_wrapper .col-xl-11 {
      float: left;
      width: 91.66667%; }
    .grid_wrapper .col-xl-10 {
      float: left;
      width: 83.33333%; }
    .grid_wrapper .col-xl-9 {
      float: left;
      width: 75%; }
    .grid_wrapper .col-xl-8 {
      float: left;
      width: 66.66667%; }
    .grid_wrapper .col-xl-7 {
      float: left;
      width: 58.33333%; }
    .grid_wrapper .col-xl-6 {
      float: left;
      width: 50%; }
    .grid_wrapper .col-xl-5 {
      float: left;
      width: 41.66667%; }
    .grid_wrapper .col-xl-4 {
      float: left;
      width: 33.33333%; }
    .grid_wrapper .col-xl-3 {
      float: left;
      width: 25%; }
    .grid_wrapper .col-xl-2 {
      float: left;
      width: 16.66667%; }
    .grid_wrapper .col-xl-1 {
      float: left;
      width: 8.33333%; } }

.site_banner {
  position: relative;
  z-index: 10; }

.block_jumbo {
  position: relative;
  z-index: 5; }
  @media (min-width: 992px) {
    .block_jumbo .row {
      align-items: center; }
    .block_jumbo .jumbo_marketing {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .block_jumbo .jumbo_conversion {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
      padding: 0; } }
  @media (min-width: 1200px) {
    .block_jumbo .jumbo_marketing {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .block_jumbo .jumbo_conversion {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }

.block_welcome {
  position: relative;
  z-index: 8; }
  @media (min-width: 768px) {
    .block_welcome .row {
      align-items: center;
      justify-content: center; }
    .block_welcome .welcome_title {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .block_welcome .welcome_content {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; } }
  @media (min-width: 992px) {
    .block_welcome .welcome_title {
      flex: 0 0 25%;
      max-width: 25%; }
    .block_welcome .welcome_content {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; } }
  @media (min-width: 1200px) {
    .block_welcome .welcome_content {
      flex: 0 0 50%;
      max-width: 50%; } }

@media (min-width: 768px) {
  .block_services .row {
    justify-content: center; }
  .block_services .service {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; } }

@media (min-width: 992px) {
  .block_services .service {
    flex: 0 0 50%;
    max-width: 50%; } }

@media (min-width: 992px) {
  .content .main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .content .sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

body {
  min-width: 380px;
  background-color: #f0f0f0;
  color: #495057;
  /*	&:not(.page_front) {
			
		@include media-breakpoint-up(md) {

			height: 100%;
			padding-bottom: 78px;

			.site_info {
				position: absolute;
				bottom: 0;
				width: 100%;
			}

		}
		
	}*/ }

html {
  font-size: 15px;
  line-height: 1.575em;
  -webkit-text-size-adjust: 100%; }
  @media (min-width: 768px) {
    html {
      font-size: 16px; } }
  @media (min-width: 992px) {
    html {
      font-size: 17px; } }
  @media (min-width: 1200px) {
    html {
      font-size: 17px; } }

body {
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 1.5em 0 0.75em;
  line-height: 1em; }
  h1 small, h2 small, h3 small, h4 small, h5 small, h6 small,
  .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small {
    display: block; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 600; }

h6, .h6 {
  font-size: 1.05rem; }
  @media (min-width: 768px) {
    h6, .h6 {
      font-size: 1.11825rem; } }
  @media (min-width: 992px) {
    h6, .h6 {
      font-size: 1.155rem; } }
  @media (min-width: 1200px) {
    h6, .h6 {
      font-size: 1.2075rem; } }

h5, .h5 {
  font-size: 1.175rem;
  letter-spacing: -0.0325em; }
  @media (min-width: 768px) {
    h5, .h5 {
      font-size: 1.25137rem; } }
  @media (min-width: 992px) {
    h5, .h5 {
      font-size: 1.2925rem; } }
  @media (min-width: 1200px) {
    h5, .h5 {
      font-size: 1.35125rem; } }

h4, .h4 {
  font-size: 1.345rem;
  letter-spacing: -0.0325em; }
  @media (min-width: 768px) {
    h4, .h4 {
      font-size: 1.43242rem; } }
  @media (min-width: 992px) {
    h4, .h4 {
      font-size: 1.4795rem; } }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.54675rem; } }

h3, .h3 {
  font-size: 1.497rem;
  font-weight: 800;
  letter-spacing: -0.035em; }
  @media (min-width: 768px) {
    h3, .h3 {
      font-size: 1.59431rem; } }
  @media (min-width: 992px) {
    h3, .h3 {
      font-size: 1.6467rem; } }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.72155rem; } }

h2, .h2 {
  font-size: 1.685rem;
  letter-spacing: -0.035em; }
  @media (min-width: 768px) {
    h2, .h2 {
      font-size: 1.79452rem; } }
  @media (min-width: 992px) {
    h2, .h2 {
      font-size: 1.8535rem; } }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 1.93775rem; } }

h1, .h1 {
  font-size: 1.855rem;
  font-weight: 800;
  letter-spacing: -0.0375em;
  text-transform: uppercase; }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 1.99412rem; } }
  @media (min-width: 992px) {
    h1, .h1 {
      font-size: 2.13325rem; } }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.17963rem; } }

.font--lg {
  font-size: 115%; }

.font--md {
  font-size: 100%; }

.font--sm {
  font-size: 85%; }

.lh--sm {
  line-height: 1.525em; }

.lh--md {
  line-height: 1.575em; }

.lh--lg {
  line-height: 1.625em; }

blockquote {
  margin: 1em 0 1em 2em;
  padding: 1em;
  font-size: 115%;
  border-left: 5px solid; }
  blockquote p {
    margin-bottom: 0.5em; }
  blockquote > *:last-child {
    margin-bottom: 0; }
  blockquote .bq--title {
    display: block;
    font-size: 1.325rem;
    font-weight: 800;
    letter-spacing: -0.0275em;
    text-transform: uppercase; }
  blockquote.bq-cite {
    border: 1px solid #ccc;
    border-left: none;
    border-right: none; }
  blockquote.bq-text {
    border-color: #495057; }
    blockquote.bq-text .bq--title {
      color: #495057; }
  blockquote.bq-primary {
    border-color: #f7931e; }
    blockquote.bq-primary .bq--title {
      color: #f7931e; }
  blockquote.bq-secondary {
    border-color: #333; }
    blockquote.bq-secondary .bq--title {
      color: #333; }
  blockquote.bq-highlight {
    border-color: #0e76bc; }
    blockquote.bq-highlight .bq--title {
      color: #0e76bc; }
  blockquote.bq-accent {
    border-color: #fd7e14; }
    blockquote.bq-accent .bq--title {
      color: #fd7e14; }
  blockquote.bq-success {
    border-color: #28a745; }
    blockquote.bq-success .bq--title {
      color: #28a745; }
  blockquote.bq-danger {
    border-color: #dc3545; }
    blockquote.bq-danger .bq--title {
      color: #dc3545; }
  blockquote.bq-warning {
    border-color: #ffd507; }
    blockquote.bq-warning .bq--title {
      color: #ffd507; }
  blockquote.bq-info {
    border-color: #6610f2; }
    blockquote.bq-info .bq--title {
      color: #6610f2; }
  blockquote.review {
    padding: 0;
    margin: 0 0 1em;
    font-size: 1rem;
    border: none; }

address {
  margin-bottom: 1em;
  font-style: normal; }
  address .address-company,
  address .address-street {
    display: block; }

p {
  margin: 0 0 1em; }
  p.lead {
    font-size: 115%;
    line-height: 1.625em; }
  p.note {
    font-size: 85%;
    line-height: 1.525em; }

hr {
  display: block;
  width: 100%;
  height: 1px;
  margin: 2em 0;
  border: none;
  background-color: #333; }
  hr.hr-inset {
    margin: 1.5em auto;
    border: none;
    background-color: #bbb;
    box-shadow: 0 1px 0 0 #fff; }

abbr, abbr[title] {
  border-bottom: 1px dotted #495057;
  text-decoration: none; }

code, data,
tt, kbd, samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace; }

mark {
  background-color: #ffd507; }

b, strong, .b {
  font-weight: 600; }

.bolder {
  font-weight: 800; }

a:link {
  color: #17a2b8; }

a:visited {
  color: #6f42c1; }

a:hover, a:focus {
  text-decoration: none;
  color: #1fc8e3; }

a:focus {
  outline: none;
  box-shadow: 0 0 1px 3px rgba(0, 0, 0, 0.2); }

a.phone {
  color: #f7931e; }
  a.phone:hover {
    color: #f7931e;
    cursor: default; }

ul, ol {
  margin: 0 0 1em 22px;
  padding-left: 0; }
  ul.list li:not(:last-child), ol.list li:not(:last-child) {
    margin-bottom: 0.5em; }
  ul.list-lower-a, ol.list-lower-a {
    list-style-type: lower-alpha; }
  ul.list-upper-a, ol.list-upper-a {
    list-style-type: upper-alpha; }
  ul.list-circle, ol.list-circle {
    list-style-type: circle; }
  ul.list-square, ol.list-square {
    list-style-type: square; }
  ul.list-decimal, ol.list-decimal {
    list-style-type: decimal; }
  ul.list-roman, ol.list-roman {
    list-style-type: lower-roman; }
  ul ul, ul ol, ol ul, ol ol {
    margin: 0.5em 0 0.5em 22px; }
  ul p, ol p {
    margin: 0.5em 0; }

ul {
  list-style-type: disc; }

ul ul {
  list-style-type: circle; }

ul ul ul {
  list-style-type: square; }

ol {
  list-style-type: decimal; }

ol ol {
  list-style-type: upper-alpha; }

ol ol ol {
  list-style-type: lower-roman; }

ol ol ol ol {
  list-style-type: lower-alpha; }

dl {
  margin: 0 0 1em; }
  dl dt {
    font-weight: 600; }
  dl dd {
    margin-inline-start: 2em; }
    dl dd:not(:last-of-type) {
      margin-bottom: 0.5em; }

ul.list-icon, ul.list-unstyled {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }
  ul.list-icon li::before, ul.list-unstyled li::before {
    content: "\200B"; }

ul.list-inline {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }
  ul.list-inline li::before {
    content: "\200B"; }
  ul.list-inline li {
    display: inline-block;
    margin: 0 0.5em 0.5em 0 !important; }

ul.list-icon {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none; }
  ul.list-icon.list-icon-center li {
    display: inline-block; }
  ul.list-icon li {
    padding-left: 2em;
    font-size: 1.125rem;
    background-repeat: no-repeat;
    background-size: 1.25rem;
    background-position: left center; }

ul.icon-check li {
  background-image: url("/_/images/icons/cm_text.svg"); }

ul.icon-check--primary li {
  background-image: url("/_/images/icons/cm_primary.svg"); }

ul.icon-check--secondary li {
  background-image: url("/_/images/icons/cm_secondary.svg"); }

ul.icon-check--success li {
  background-image: url("/_/images/icons/cm_success.svg"); }

ul.icon-check--accent li {
  background-image: url("/_/images/icons/cm_accent.svg"); }

ul.icon-check--highlight li {
  background-image: url("/_/images/icons/cm_highlight.svg"); }

figure {
  margin: 0 0 1em;
  padding: 0.5em;
  border: 1px solid #ccc; }
  figure figcaption {
    font-size: 15px;
    font-style: italic; }

iframe {
  display: block;
  border: 1px solid #ccc; }

img {
  display: block;
  border-style: none; }

.img-resonsive {
  width: 100%; }

.img-captioned {
  max-width: 380px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-bottom: 15px; }
  .img-captioned img {
    padding: 0;
    border: none;
    margin-bottom: 0; }
  .img-captioned span {
    display: block;
    max-width: 360px;
    margin: 5px 0 0;
    font-size: 0.95rem;
    line-height: 1.275em;
    color: #606a73; }

.img-main {
  margin: 0 0 15px;
  max-width: 360px; }
  @media (min-width: 992px) and (max-width: 1199px) {
    .service .img-main {
      max-width: 240px; } }
  @media (min-width: 1200px) {
    .service .img-main {
      max-width: 300px; } }

.img-thumbnail {
  padding: 5px;
  border: 1px solid #ddd;
  background-color: #fff; }

@media (min-width: 768px) {
  .img-right {
    clear: both;
    float: right;
    width: 45%;
    margin: 0 0 15px 20px; } }

@media (min-width: 768px) {
  .img-left {
    clear: both;
    float: left;
    width: 45%;
    margin: 0 20px 15px 0; } }

.img-wide {
  margin: 0 0 15px;
  max-width: none; }

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed-responsive.embed-responsive-16by9 {
    padding-bottom: 56.25%; }
  .embed-responsive.embed-responsive-4by3 {
    padding-bottom: 75%; }
  .embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

.hidden {
  display: none !important; }

@media (max-width: 575px) {
  .hidden--xs {
    display: none !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .hidden--sm {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden--md {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden--lg {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden--xl {
    display: none !important; } }

[class*="visible-"] {
  display: none; }

@media (max-width: 575px) {
  .visible--xs {
    display: block !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .visible--sm {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible--md {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible--lg {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible--xl {
    display: block !important; } }

.db {
  display: block; }

.mt0 {
  margin-top: 0 !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mt30 {
  margin-top: 30px !important; }

.mt15 {
  margin-top: 15px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.notrfm {
  transform: none !important; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.ff--sans {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important; }

.ff--serif {
  font-family: "Times New Roman", Georgia, Times, serif !important; }

.ff--monospace {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace !important; }

.txt--up {
  text-transform: uppercase !important; }

.txt--cap {
  text-transform: capitalize !important; }

.txt--lg {
  font-size: 115% !important; }

.txt--md {
  font-size: inherit !important; }

.txt--sm {
  font-size: 85% !important; }

.lh1 {
  line-height: 1.1em !important; }

.lh125 {
  line-height: 1.25em !important; }

.txt--left {
  text-align: left !important; }

.txt--right {
  text-align: right !important; }

.txt--center {
  text-align: center !important; }

/*.b0 { font-weight: $font-weight-normal !important; } }
.b1 { font-weight: $font-weight-bold !important; } }
.b2 { font-weight: $font-weight-bolder !important; } }*/
.color--text {
  color: #495057; }

.color--white {
  color: #fff; }

.color--primary {
  color: #f7931e; }

.color--secondary {
  color: #333; }

.color--highlight {
  color: #0e76bc; }

.color--accent {
  color: #fd7e14; }

.color--link {
  color: #17a2b8; }

.color--review {
  color: #f90; }

.color--success {
  color: #28a745; }

.color--danger {
  color: #dc3545; }

.color--warning {
  color: #ffd507; }

.color--info {
  color: #6610f2; }

.bg--text {
  background-color: #495057; }

.bg--white {
  background-color: #fff; }

.bg--primary {
  background-color: #f7931e; }

.bg--secondary {
  background-color: #333; }

.bg--highlight {
  background-color: #0e76bc; }

.bg--link {
  background-color: #17a2b8; }

.bg--review {
  background-color: #f90; }

.bg--success {
  background-color: #28a745; }

.bg--danger {
  background-color: #dc3545; }

.bg--warning {
  background-color: #ffd507; }

.bg--info {
  background-color: #6610f2; }

.gdfancybg--text {
  background: #495057;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.22) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.22) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%), #495057; }

.gdfancybg--white {
  background: #fff;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.22) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.22) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%), #fff; }

.gdfancybg--primary {
  background: #f7931e;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.22) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.22) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%), #f7931e; }

.gdfancybg--secondary {
  background: #333;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.22) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.22) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%), #333; }

.gdfancybg--highlight {
  background: #0e76bc;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.22) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.22) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%), #0e76bc; }

.gdfancybg--review {
  background: #f90;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.22) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.22) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%), #f90; }

.gdfancybg--success {
  background: #28a745;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.22) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.22) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%), #28a745; }

.gdfancybg--danger {
  background: #dc3545;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.22) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.22) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%), #dc3545; }

.gdfancybg--warning {
  background: #ffd507;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.22) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.22) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%), #ffd507; }

.gdfancybg--info {
  background: #6610f2;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.22) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.22) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%), #6610f2; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1em;
  line-height: 1em;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .table caption {
    margin: 1em 0 0.5em; }
  .table * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .table th {
    font-weight: 600;
    text-align: left; }
  .table th,
  .table td {
    padding: 0.5em 0.75em;
    vertical-align: top;
    border-top: 1px solid #ccc; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ccc; }
  .table tbody + tbody {
    border-top: 2px solid #ccc; }

.table-bordered {
  border: 1px solid #ccc; }
  .table-bordered th, .table-bordered td {
    border: 1px solid #ccc; }
  .table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: #e5e5e5;
  cursor: pointer; }

@media (max-width: 767px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive > .table-bordered {
      border: 0; } }

.embed_responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed_responsive.embed_responsive_16by9 {
    padding-bottom: 56.25%; }
  .embed_responsive.embed_responsive_4by3 {
    padding-bottom: 75%; }
  .embed_responsive .embed_responsive_item, .embed_responsive embed, .embed_responsive iframe, .embed_responsive object, .embed_responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

/*@import "components/icons";*/
.ad-box {
  max-width: 800px;
  margin: 0 auto 20px;
  padding: 20px;
  text-align: center;
  border: 1px solid #ccc;
  background-color: #fff; }
  .ad-box img {
    display: block;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    vertical-align: middle; }
  .ad-box address {
    display: block;
    margin: 10px 0 0;
    font-size: 14px;
    line-height: 1em; }
  .ad-box .call {
    margin: 0;
    font-size: 17px;
    line-height: 1em; }
    .ad-box .call strong {
      display: block;
      margin: 15px 0 0;
      line-height: 1em; }
    .ad-box .call .phone {
      font-size: 36px;
      font-weight: 800;
      line-height: 1em;
      letter-spacing: -3px;
      color: #5fa3d3; }
  @media (min-width: 768px) {
    .ad-box {
      overflow: hidden;
      text-align: left; }
      .ad-box .ad-head {
        float: left;
        width: 360px;
        padding-right: 30px; }
      .ad-box .ad-foot {
        float: left; }
      .ad-box .call strong {
        margin-top: 0; } }

.abox {
  max-width: 360px;
  margin: 0 auto 20px;
  padding: 15px;
  font-size: 14;
  font-size: 0.875rem;
  text-align: center;
  line-height: 1em;
  border: 1px solid #ccc;
  background-color: #fff; }
  .abox img {
    display: block;
    width: 100%;
    margin: 0 auto 8px; }
  .abox a.ab-logo {
    display: block;
    margin: 0 auto 15px;
    font-weight: 600;
    color: #f7931e !important; }
  .abox p {
    margin-bottom: 8px; }
  .abox p.call .phone {
    font-size: 24;
    font-size: 1.5rem;
    font-weight: 800;
    color: #495057; }
  .abox p:not(.call) {
    line-height: 1.325em; }

.list-companies-free p {
  margin: 0;
  padding: 15px; }
  .list-companies-free p:nth-child(even) {
    background-color: #fff; }

.list-companies-free strong, .list-companies-free span {
  display: block;
  font-size: 115%;
  font-weight: 600; }

@media (min-width: 768px) {
  .list-companies-free p {
    position: relative; }
  .list-companies-free span {
    position: absolute;
    right: 15px;
    top: 27px; } }

.sidebox {
  max-width: 360px;
  margin: 30px auto;
  padding: 30px;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  border: 1px solid #ccc;
  background-color: white; }
  .sidebox img {
    display: block;
    margin: 0 auto 15px;
    max-width: 100%; }
  .sidebox .phone {
    font-size: 32px;
    font-weight: 800; }
  .sidebox h3 {
    margin: 0 0 5px; }
  .sidebox p {
    margin-bottom: 5px; }
  .sidebox p.lead strong {
    font-weight: 800;
    letter-spacing: -0.0575em; }
  .sidebox p:last-of-type {
    margin-bottom: 0; }
  .sidebox hr {
    margin: 10px 0; }

@media (min-width: 992px) {
  .sidebar > *:first-child {
    margin-top: 0; } }

/*@import "modules/address/address";*/
/*@import "modules/alert/alerts";*/
/*@import "modules/blog_roll/blog_roll";*/
.btn {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  display: inline-block;
  padding: 12px 20px;
  font-weight: 800;
  font-style: italic;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 2px solid #117a8b;
  background-color: #148ea1;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  line-height: 1em;
  cursor: pointer; }
  .btn:link, .btn:visited, .btn:active {
    border: 2px solid #10707f;
    background-color: #148ea1;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn:hover, .btn:focus {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    border-color: #17a2b8;
    background-color: #1ab6cf;
    color: #fff; }
  .btn small {
    display: block;
    line-height: 1em; }
  .btn:focus {
    outline: 4px solid rgba(0, 0, 0, 0.25); }

.btn-text {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  display: inline-block;
  padding: 12px 20px;
  font-weight: 800;
  font-style: italic;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 2px solid #32373b;
  background-color: #3d4349;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-text:link, .btn-text:visited, .btn-text:active {
    border: 2px solid #2c3034;
    background-color: #3d4349;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-text:hover, .btn-text:focus {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    border-color: #495057;
    background-color: #555d65;
    color: #fff; }

.btn-link {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  display: inline-block;
  padding: 12px 20px;
  font-weight: 800;
  font-style: italic;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 2px solid #117a8b;
  background-color: #148ea1;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-link:link, .btn-link:visited, .btn-link:active {
    border: 2px solid #10707f;
    background-color: #148ea1;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-link:hover, .btn-link:focus {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    border-color: #17a2b8;
    background-color: #1ab6cf;
    color: #fff; }

.btn-primary {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  display: inline-block;
  padding: 12px 20px;
  font-weight: 800;
  font-style: italic;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 2px solid #da7908;
  background-color: #f38709;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-primary:link, .btn-primary:visited, .btn-primary:active {
    border: 2px solid #ce7207;
    background-color: #f38709;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-primary:hover, .btn-primary:focus {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    border-color: #f7931e;
    background-color: #f89f37;
    color: #fff; }

.btn-secondary {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  display: inline-block;
  padding: 12px 20px;
  font-weight: 800;
  font-style: italic;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 2px solid #1a1a1a;
  background-color: #262626;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-secondary:link, .btn-secondary:visited, .btn-secondary:active {
    border: 2px solid #131313;
    background-color: #262626;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-secondary:hover, .btn-secondary:focus {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    border-color: #333;
    background-color: #404040;
    color: #fff; }

.btn-highlight {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  display: inline-block;
  padding: 12px 20px;
  font-weight: 800;
  font-style: italic;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 2px solid #0a588d;
  background-color: #0c67a4;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-highlight:link, .btn-highlight:visited, .btn-highlight:active {
    border: 2px solid #0a5181;
    background-color: #0c67a4;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-highlight:hover, .btn-highlight:focus {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    border-color: #0e76bc;
    background-color: #1085d4;
    color: #fff; }

.btn-accent {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  display: inline-block;
  padding: 12px 20px;
  font-weight: 800;
  font-style: italic;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 2px solid #dc6502;
  background-color: #f57102;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-accent:link, .btn-accent:visited, .btn-accent:active {
    border: 2px solid #cf5f02;
    background-color: #f57102;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-accent:hover, .btn-accent:focus {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    border-color: #fd7e14;
    background-color: #fd8c2d;
    color: #fff; }

.btn-success {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  display: inline-block;
  padding: 12px 20px;
  font-weight: 800;
  font-style: italic;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 2px solid #1e7e34;
  background-color: #23923d;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-success:link, .btn-success:visited, .btn-success:active {
    border: 2px solid #1c7430;
    background-color: #23923d;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-success:hover, .btn-success:focus {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    border-color: #28a745;
    background-color: #2dbc4e;
    color: #fff; }

.btn-danger {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  display: inline-block;
  padding: 12px 20px;
  font-weight: 800;
  font-style: italic;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 2px solid #bd2130;
  background-color: #d32535;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-danger:link, .btn-danger:visited, .btn-danger:active {
    border: 2px solid #b21f2d;
    background-color: #d32535;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-danger:hover, .btn-danger:focus {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    border-color: #dc3545;
    background-color: #e04b59;
    color: #fff; }

.btn-info {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  display: inline-block;
  padding: 12px 20px;
  font-weight: 800;
  font-style: italic;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 2px solid #510bc4;
  background-color: #5b0cdd;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-info:link, .btn-info:visited, .btn-info:active {
    border: 2px solid #4c0ab8;
    background-color: #5b0cdd;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-info:hover, .btn-info:focus {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    border-color: #6610f2;
    background-color: #7528f3;
    color: #fff; }

.btn-review {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  display: inline-block;
  padding: 12px 20px;
  font-weight: 800;
  font-style: italic;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 2px solid #cc7a00;
  background-color: #e68a00;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-review:link, .btn-review:visited, .btn-review:active {
    border: 2px solid #bf7300;
    background-color: #e68a00;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-review:hover, .btn-review:focus {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    border-color: #f90;
    background-color: #ffa31a;
    color: #fff; }

.btn-warning {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  display: inline-block;
  padding: 12px 20px;
  font-weight: 800;
  font-style: italic;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 2px solid #d3af00;
  background-color: #edc400;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-warning:link, .btn-warning:visited, .btn-warning:active {
    border: 2px solid #c6a500;
    background-color: #edc400;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn-warning:hover, .btn-warning:focus {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    border-color: #ffd507;
    background-color: #ffd921;
    color: #fff; }

.btn-block {
  display: block;
  text-align: center; }

.btn-close {
  float: right;
  margin: 0 0 0 15px;
  padding: 5px;
  font-size: 24px;
  line-height: 1em;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #a71d2a;
  background-color: #dc3545;
  color: #fff;
  opacity: 0.8;
  cursor: pointer; }
  .btn-close .close_img {
    width: 0.75rem; }
  .btn-close:hover {
    background-color: #921925; }

.btn--sm {
  padding: 8px 12px;
  font-size: 0.9rem; }

.btn--md {
  padding: 12px 20px;
  font-size: 1rem; }

.btn--lg {
  padding: 15px 28px;
  font-size: 1.1rem; }

.btn--wide {
  display: block; }

.btn--inline {
  display: inline-block; }

.button-mobile {
  padding: 0 15px;
  line-height: 1em;
  background: transparent; }
  .button-mobile .mobile_toggle {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    width: 48px;
    height: 41px;
    padding: 10px 11px 9px;
    background-color: #f9ab4f;
    border: none;
    border-radius: 4px;
    color: #fff; }
    .button-mobile .mobile_toggle:hover, .button-mobile .mobile_toggle:focus {
      -webkit-transition: all ease-in-out 200ms;
      -moz-transition: all ease-in-out 200ms;
      -ms-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      outline: none;
      background-color: #4d4d4d;
      cursor: pointer; }
  .button-mobile .button-bars {
    display: inline-block;
    height: 100%;
    width: 26px; }
  .button-mobile .icon-bar {
    -webkit-transition: transform ease-in-out 200ms;
    -moz-transition: transform ease-in-out 200ms;
    -ms-transition: transform ease-in-out 200ms;
    -o-transition: transform ease-in-out 200ms;
    transition: transform ease-in-out 200ms;
    display: block;
    width: 100%;
    height: 3px;
    border-radius: 4px;
    background-color: #fff; }
    .button-mobile .icon-bar:nth-child(2) {
      margin: 5px 0; }
  .button-mobile b {
    display: none; }
  .canvas-slid .button-mobile .icon-bar:nth-child(2) {
    visibility: hidden; }
  .canvas-slid .button-mobile .icon-bar:nth-child(1), .canvas-slid .button-mobile .icon-bar:nth-child(3) {
    -webkit-transition: transform ease-in-out 200ms;
    -moz-transition: transform ease-in-out 200ms;
    -ms-transition: transform ease-in-out 200ms;
    -o-transition: transform ease-in-out 200ms;
    transition: transform ease-in-out 200ms; }
  .canvas-slid .button-mobile .icon-bar:nth-child(1) {
    transform: rotate(45deg);
    margin-top: 9px; }
  .canvas-slid .button-mobile .icon-bar:nth-child(3) {
    transform: rotate(-45deg);
    margin-top: -16px; }

.form_quote, .coverage, .review, .block_services .service_wrap, .company_form {
  padding: 1.5em;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 1.75em 2em -2em rgba(0, 0, 0, 0.5); }

.phone {
  text-decoration: none;
  cursor: default; }
  .phone:hover {
    text-decoration: none;
    outline: none;
    cursor: default; }

.phone.mask {
  color: #495057; }
  .phone.mask:hover {
    outline: none;
    color: #495057; }

.phone.inherit {
  color: inherit; }
  .phone.inherit:hover {
    outline: none;
    color: inherit; }

.contact-call {
  font-size: 1.8rem;
  line-height: 1em; }

.contact-title {
  display: block;
  font-size: 1rem;
  line-height: 1em; }
  .contact-call .contact-title {
    color: #868e96; }

.contact-phone {
  letter-spacing: -0.0325em;
  line-height: 1em;
  font-weight: 800; }
  .contact-call .contact-phone {
    color: #fd7e14; }

/*@import "modules/copyright/copyright";*/
/*@import "modules/faq/faq";*/
.form {
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.form_title {
  margin: 0 0 0.5em;
  font-size: 1.4rem;
  font-weight: 800; }
  .form_title small {
    display: block;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: uppercase; }

.form_group.form_hp {
  display: none; }

.form_group.form_spam {
  margin: 0 0 5px; }

.form_error {
  border: 1px solid #dc3545;
  background-color: #fae3e5; }

.error-message {
  margin: -1px 0 0 3px;
  padding: 0 0 2px !important;
  font-size: 12px;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: -0.0275em;
  text-transform: uppercase;
  color: #d32535; }

.form-control {
  display: block;
  width: 100%;
  margin: 0 0 5px;
  padding: 10px 8px;
  font-size: 1rem;
  border: 1px solid #ddd;
  outline: none;
  background-color: #fff; }
  .form-control:focus {
    border-color: #ffd507;
    background-color: #fff8d3; }

textarea.form_control {
  min-height: 120px; }

/*	.form_spam {
		@include media-breakpoint-down(xs) {
			position: relative;
			overflow: hidden;
			height: 75px;
			.g-recaptcha {
				@include transform(scale(0.8));
				position: absolute;
				top: 0;
				left: -18px;
			}
		}
	}*/
@media (min-width: 768px) {
  .form_lr .form_group {
    padding: 0 0.25em 0.1em; }
  .form_lr .form_left,
  .form_lr .form_right {
    width: 50%; }
  .form_lr .form_left {
    float: left;
    clear: both; }
  .form_lr .form_right {
    float: right; }
  .form_lr .form_clear {
    clear: both; }
  .form_lr .form_state {
    width: 20%;
    clear: none; }
  .form_lr .form_zip {
    width: 30%; } }

@media (min-width: 768px) and (max-width: 991px) {
  .form_lr--sm .form_group {
    padding: 0 0.25em 0.1em; }
  .form_lr--sm .form_left,
  .form_lr--sm .form_right {
    width: 50%; }
  .form_lr--sm .form_left {
    float: left; }
  .form_lr--sm .form_right {
    float: right; }
  .form_lr--sm .form_clear {
    clear: both; } }

.form_quote .form_title {
  text-transform: uppercase; }
  .form_quote .form_title small {
    font-weight: 600;
    color: #f9ab4f; }

.form_quote .form_group_wrap {
  overflow: hidden; }
  .form_quote .form_group_wrap > label {
    display: block;
    margin: 0 0 0 5px;
    font-size: 0.95rem;
    font-weight: 600; }
  .form_quote .form_group_wrap .form_zip_from {
    float: left;
    width: 49%; }
  .form_quote .form_group_wrap .form_zip_to {
    float: right;
    width: 49%; }

.form_quote .form_comments textarea {
  height: 120px; }

@media (min-width: 768px) and (max-width: 991px) {
  .form_quote .form_left, .form_quote .form_zip_from {
    float: left;
    width: 49.5% !important; }
  .form_quote .form_right, .form_quote .form_zip_to {
    float: right;
    width: 49.5% !important; }
  .form_quote .form_clear {
    clear: both; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .form_quote .form_spam {
    position: relative;
    overflow: hidden;
    height: 65px; }
    .form_quote .form_spam .g-recaptcha {
      -webkit-transform: scale(0.835);
      -moz-transform: scale(0.835);
      -o-transform: scale(0.835);
      transform: scale(0.835);
      position: absolute;
      top: -6px;
      left: -24px; } }

/*@import "modules/form/form_review";*/
/*@import "modules/gallery/gallery";*/
/*@import "modules/gallery/gallery_featured";*/
/*@import "modules/hours/hours";*/
.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99000;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none; }

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 99500;
  text-align: center;
  line-height: 0;
  font-weight: 400; }
  @media (min-width: 768px) {
    .lightbox {
      margin-top: 75px; } }

.lightbox .lb-image {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 3px; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  padding: 5px;
  overflow: hidden;
  background-color: white;
  *zoom: 1;
  margin: 0 auto;
  border-radius: 3px 3px 0 0; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url("/_/images/layout/loading.gif") no-repeat; }

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="); }

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url("/_/images/layout/prev.png") left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url("/_/images/layout/next.png") right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding: 7px 0;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #fff;
  color: #495057; }

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-data {
  padding: 0 4px; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  display: inline-block;
  padding: 6px 5px 0;
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1em; }

.lb-data .lb-number {
  display: none !important;
  visibility: hidden !important; }

.lb-data .lb-close {
  display: block;
  float: right;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  background: url("/_/images/icons/close-red.svg") center center no-repeat;
  background-size: 20px 20px;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.logo {
  display: block;
  width: 100%;
  text-decoration: none; }
  .logo img {
    display: block;
    width: 100%; }

.logo-banner {
  max-width: 320px; }

/*@import "modules/media_object/media_object";*/
/*@import "modules/modal/modal";*/
.nav-menu {
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style-type: none; }

.menu-item {
  box-sizing: border-box;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }
  .menu-item.active .menu-link {
    cursor: default; }
    .menu-item.active .menu-link:hover {
      cursor: default; }
  .menu-item.open .dropdown-menu {
    display: block;
    height: auto; }
  .menu-item.open .caret {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }

.menu-link {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  display: block;
  padding: 12px 15px;
  line-height: 1em;
  text-decoration: none; }
  .menu-link:hover, .menu-link:focus {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    outline: none; }

.caret {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 0.9em;
  height: 0.675em;
  background-image: url("/_/images/icons/dropdown-white.svg");
  background-repeat: no-repeat;
  background-size: 0.675em;
  background-position: center center; }

.dropdown-toggle:hover .caret,
.open .dropdown-toggle .caret {
  background-image: url("/_/images/icons/dropdown-white.svg"); }

.dropdown-menu {
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  display: none;
  position: relative;
  z-index: 95000;
  margin: 0;
  padding: 1em;
  min-width: 280px; }
  .dropdown-menu .menu-item {
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
    text-align: left; }
  .dropdown-menu .menu-link {
    padding-left: 10px; }
    .dropdown-menu .menu-link:hover, .dropdown-menu .menu-link:focus {
      outline: none; }
  @media (min-width: 768px) {
    .dropdown-menu {
      position: absolute; } }

.coverage-title {
  margin: 0 0 0.75rem;
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase; }

.coverage-list {
  margin-bottom: 0.5rem;
  line-height: 1.4em; }
  .coverage-list:last-of-type {
    margin-bottom: 0; }

.coverage-county {
  font-size: 1rem;
  font-weight: 600; }

.coverage-city {
  font-size: 0.875rem; }

.page_title {
  padding: 1.5em 0;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  border-bottom: 3px solid #fff;
  color: #fff;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.2) 35%, rgba(255, 255, 255, 0) 35%), linear-gradient(45deg, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.2) 75%, rgba(255, 255, 255, 0) 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0) 90%), linear-gradient(to right, #dc6502, #fd9a47, #fd9a47, #dc6502); }
  .page_title h1 {
    margin: 0;
    padding: 0.25em 0;
    line-height: 1em; }
    .page_title h1 small {
      color: #fff; }
  @media (min-width: 768px) {
    .page_title {
      padding: 6em 0 1em; } }
  @media (min-width: 992px) {
    .page_title {
      padding-top: 8em; } }

.review {
  border-radius: 6px; }
  .review img.review-stars {
    display: block;
    width: 120px;
    margin: 0 auto 0.75em; }
  .review p {
    margin-bottom: 0.75em; }
  .review cite {
    font-size: 1.2rem;
    font-weight: 800;
    text-transform: uppercase;
    color: #f90; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

.slick-slide img {
  display: block;
  margin: 0; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/_/images/layout/ajax-loader.gif") center center no-repeat; }

/* Arrows */
.slick-prev,
.slick-next {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  z-index: 95000;
  position: absolute;
  display: block;
  height: 100%;
  top: 0;
  bottom: 0;
  width: 55px;
  font-size: 0px;
  line-height: 0px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  color: transparent;
  padding: 0;
  border: none;
  outline: none;
  opacity: 0; }
  .slick-prev:hover,
  .slick-next:hover {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    outline: none;
    opacity: 0.6; }

.slick-prev {
  left: 0;
  background-image: url("/_/images/icons/angle-left-black.png"); }

.slick-next {
  right: 0;
  background-image: url("/_/images/icons/angle-right-black.png"); }

/*@import "modules/slideshow/slideshow";*/
/*@import "modules/slideshow/slideshow_jumbo";*/
.reviews_slider {
  /*	@include media-breakpoint-up(lg) {

		.slick-prev,
		.slick-next {
			width: 44px;
			background-size: 40%;
		}

	}*/ }
  .reviews_slider .slick-prev,
  .reviews_slider .slick-next {
    width: 44px;
    background-color: #fff;
    background-size: 35%;
    opacity: 1; }
    .reviews_slider .slick-prev:hover,
    .reviews_slider .slick-next:hover {
      background-color: #d9d9d9;
      opacity: 1; }
  .reviews_slider .slick-prev {
    left: 0;
    border-radius: 6px 0 0 6px;
    background-image: url("/_/images/icons/angle-left-review.png"); }
  .reviews_slider .slick-next {
    right: 0;
    border-radius: 0 6px 6px 0;
    background-image: url("/_/images/icons/angle-right-review.png"); }

.gallery_slider {
  padding-bottom: 1.5em;
  /*	.slick-prev {
		display: none !important;
	}

	.slick-prev,
	.slick-next {
		width: 45px;
		background-color: theme(primary, light);
		background-size: 50%;
		opacity: 1;
		&:hover {
			background-color: theme(primary, dark);
			opacity: 1;
		}
	}*/ }
  .gallery_slider a {
    display: block;
    padding: 0 5px;
    box-shadow: 0 25px 30px -30px rgba(0, 0, 0, 0.8); }
    .gallery_slider a img {
      display: block;
      width: 100%;
      margin: 0;
      padding: 5px 5px 0;
      background-color: #f0f0f0; }

/*@import "modules/video/video";*/
/*@import "modules/widget/widget";*/
.block {
  padding: 2em 0; }

.block-title {
  margin-top: 0; }

.block_more {
  text-align: center; }

/*@import "templates/block_site_alert";*/
.block_jumbo {
  padding: 3em 0;
  background: #222;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.22) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.22) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%), #222; }
  @media (min-width: 768px) {
    .block_jumbo {
      background: url("/_/images/layout/jumbo-lg.jpg"), linear-gradient(to bottom, #fff 410px, #333 410px);
      background-size: auto 410px, 100% 100%;
      background-position: top center, center center;
      background-repeat: no-repeat, repeat-x; } }
  @media (min-width: 992px) {
    .block_jumbo {
      padding: 0;
      background: #333 url("/_/images/layout/jumbo-xl.jpg") top left no-repeat;
      background-size: cover; }
      .block_jumbo .wrap {
        padding-top: 2.5em;
        padding-bottom: 2.5em; } }
  @media (min-width: 1200px) {
    .block_jumbo {
      background-size: 1320px 100%;
      background-position: center center; }
      .block_jumbo .wrap {
        padding-top: 3.5em;
        padding-bottom: 3.5em; } }

.jumbo_marketing {
  padding: 0 15px 2em;
  text-align: center; }
  .jumbo_marketing h1 {
    margin-top: 0;
    font-size: 1.5rem;
    font-weight: 600;
    font-style: italic;
    line-height: 1.325em;
    text-transform: none;
    color: #fff; }
    .jumbo_marketing h1 b, .jumbo_marketing h1 small {
      display: inline;
      padding: 0.25em 0.75em 0.25em 0.5em;
      box-decoration-break: clone; }
    .jumbo_marketing h1 b {
      padding-top: 0.1em;
      font-weight: 800;
      background-color: rgba(247, 147, 30, 0.9);
      box-shadow: 5px 0 0 rgba(247, 147, 30, 0.9), -5px 0 0 rgba(247, 147, 30, 0.9); }
    .jumbo_marketing h1 small {
      padding-top: 0.125em;
      font-size: 1.1rem;
      line-height: 1em;
      letter-spacing: 0.0325em;
      background-color: rgba(51, 51, 51, 0.9);
      box-shadow: 5px 0 0 rgba(51, 51, 51, 0.9), -5px 0 0 rgba(51, 51, 51, 0.9); }
  .jumbo_marketing ul.marketing-list {
    margin-bottom: 0; }
    .jumbo_marketing ul.marketing-list li {
      padding-left: 1.5em;
      font-weight: 600;
      color: #fff; }
  @media (min-width: 576px) {
    .jumbo_marketing h1 {
      font-size: 1.7rem;
      line-height: 1.25em; }
      .jumbo_marketing h1 small {
        font-size: 1.3rem;
        line-height: 1em; }
    .jumbo_marketing ul.marketing-list li {
      font-size: 1.2rem; } }
  @media (min-width: 768px) {
    .jumbo_marketing {
      padding-bottom: 2.5em; }
      .jumbo_marketing ul.marketing-list li {
        padding: 2px 5px 2px 1.5em;
        background-color: rgba(238, 239, 233, 0.8);
        background-position: 5px center;
        color: #495057; } }
  @media (min-width: 992px) {
    .jumbo_marketing {
      padding: 0 15px; }
      .jumbo_marketing h1 {
        margin-bottom: 0.5em;
        font-size: 2rem;
        line-height: 1.25em; }
        .jumbo_marketing h1 b {
          padding: 0.1em 0.5em 0.25em 0.25em; }
        .jumbo_marketing h1 small {
          padding: 0.15em 0.25em 0.15em 0.25em; }
        .jumbo_marketing h1 small {
          font-size: 1.5rem;
          line-height: 1em; }
      .jumbo_marketing ul.marketing-list li {
        font-size: 1.3rem; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .jumbo_conversion .form_spam {
    height: 79px; }
    .jumbo_conversion .form_spam .g-recaptcha {
      -webkit-transform: scale(0.945);
      -moz-transform: scale(0.945);
      -o-transform: scale(0.945);
      transform: scale(0.945);
      top: -3px;
      left: -9px; } }

@media (min-width: 992px) {
  .jumbo_conversion {
    background-color: rgba(255, 255, 255, 0.8); }
    .jumbo_conversion .form {
      padding: 0 1em;
      border: none;
      background-color: transparent;
      box-shadow: none; } }

.block_welcome {
  text-align: center;
  background-color: #f7931e;
  color: #fff; }
  @media (min-width: 768px) {
    .block_welcome .wrap > *:last-child {
      margin-bottom: 0; }
    .block_welcome .welcome_title {
      text-align: right; }
    .block_welcome .welcome_content {
      text-align: left; } }
  @media (min-width: 992px) {
    .block_welcome {
      border-top: 3px solid #fff;
      box-shadow: 0 -5px 8px 0 rgba(0, 0, 0, 0.3); } }

.welcome-title {
  font-style: italic;
  font-weight: 800;
  letter-spacing: -0.0375em;
  text-transform: uppercase; }
  .welcome-title span {
    font-size: 80%;
    line-height: 1em;
    display: block; }

.welcome_content > *:last-child {
  margin-bottom: 0; }

.block_services {
  background-image: linear-gradient(to bottom, #f7931e 170px, transparent 170px);
  padding-bottom: 4em; }
  .block_services .service_wrap {
    padding: 20px; }
  .block_services .service {
    text-align: center; }
    .block_services .service:not(:last-of-type) {
      margin-bottom: 2em; }
    .block_services .service h3 {
      margin: 15px 0 10px;
      line-height: 1em;
      letter-spacing: -0.0575em;
      text-transform: uppercase;
      color: #f7931e; }
    .block_services .service img {
      width: 100%;
      max-width: 120px;
      margin: 0 auto; }
  .block_services .service_content > *:last-child {
    margin-bottom: 0; }
  @media (min-width: 768px) {
    .block_services {
      padding-top: 0;
      background-image: linear-gradient(to bottom, #f7931e 90px, transparent 90px); }
      .block_services .service_wrap {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
        margin-left: 0;
        margin-right: 0;
        text-align: left;
        align-items: center; }
        .block_services .service_wrap .service_media {
          flex: 0 0 25%;
          max-width: 25%;
          padding: 0 15px 0 0; }
        .block_services .service_wrap .service_content {
          flex: 0 0 75%;
          max-width: 75%; }
          .block_services .service_wrap .service_content h3 {
            margin-top: 0; } }
  @media (min-width: 992px) {
    .block_services {
      background-image: linear-gradient(to bottom, #f7931e 140px, transparent 140px); }
      .block_services .service_wrap .service_media {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
      .block_services .service_wrap .service_content {
        flex: 0 0 66.66667%;
        max-width: 66.66667%; } }
  @media (min-width: 1200px) {
    .block_services {
      background-image: linear-gradient(to bottom, #f7931e 120px, transparent 120px); } }

.main .form_quote {
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none; }

.company_form {
  padding: 20px;
  margin-bottom: 1em; }
  @media (min-width: 992px) {
    .company_form {
      float: right;
      width: 298px;
      margin: 0 0 10px 10px; } }
  @media (min-width: 1200px) {
    .company_form {
      width: 345px; } }

.company_coverage .coverage_title {
  font-size: 1.1rem;
  line-height: 1.325em; }

.company_coverage .coverage_city {
  font-size: 0.9rem;
  line-height: 1.325em; }

.company_block .company_logo {
  width: 100%;
  margin: 0 auto 15px; }

.company_block .company_contact {
  text-align: center; }
  .company_block .company_contact .phone {
    font-size: 28px;
    font-weight: 800;
    letter-spacing: -0.0325em;
    line-height: 1em; }

.company_block .company_address {
  font-size: 0.9rem;
  line-height: 1em;
  text-align: center; }
  .company_block .company_address .address_title {
    display: block;
    margin-bottom: 5px;
    font-size: 1.3rem;
    line-height: 1em; }
    .company_block .company_address .address_title small {
      display: block; }

.company_block .list-company {
  font-weight: 600;
  text-align: center; }
  .company_block .list-company li {
    padding-left: 1.5em; }

@media (min-width: 768px) and (max-width: 991px) {
  .company_block .company_wrapper {
    display: flex;
    align-items: center; }
  .company_block .company_media {
    padding-right: 15px;
    margin-right: 15px;
    border-right: 1px solid #ccc; }
    .company_block .company_media .company_logo {
      margin-bottom: 0; }
  .company_block .company_extra {
    clear: both;
    margin-top: 2em; }
    .company_block .company_extra .list-company br {
      display: none; }
    .company_block .company_extra .list-company li {
      margin-right: 10px; } }

.site_banner {
  padding: 2.5em 0;
  text-align: center;
  border-top: 10px solid #333;
  border-bottom: 5px solid #ccc;
  background-color: #fff;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.3); }
  .site_banner .button-mobile {
    margin-bottom: 0;
    text-align: left; }
  .site_banner .logo-banner {
    margin: 0 auto; }
  .site_banner .contact-call {
    margin: 0; }

.site_info {
  text-align: center;
  background-color: #333;
  color: #fff; }
  .site_info a {
    color: #fff; }

ul.list-copyright {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }
  ul.list-copyright li::before {
    content: "\200B"; }
  @media (min-width: 768px) {
    ul.list-copyright {
      margin-left: 0;
      padding-left: 0;
      list-style-type: none;
      list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }
      ul.list-copyright li::before {
        content: "\200B"; }
      ul.list-copyright li {
        display: inline-block;
        margin: 0 0.5em 0.5em 0 !important; }
      ul.list-copyright li {
        margin-bottom: 0 !important; } }

.menu_states {
  border-top: 1px solid #ccc;
  background-color: #fff;
  padding: 2em 0;
  text-align: center;
  box-shadow: none; }
  .menu_states .nav-menu .menu-item {
    display: inline-block;
    font-size: 14px;
    line-height: 1em;
    margin: 3px; }
    .menu_states .nav-menu .menu-item.active .menu-link {
      background-color: #f7931e; }
      .menu_states .nav-menu .menu-item.active .menu-link:hover {
        background-color: #f7931e;
        cursor: default; }
  .menu_states .nav-menu .menu-link {
    display: block;
    padding: 6px 7px;
    border-radius: 3px;
    background-color: #17a2b8;
    color: #fff; }
    .menu_states .nav-menu .menu-link:hover {
      background-color: #1fc8e3; }
  .menu_states .nav-menu .menu-link[href*="#"] {
    background-color: #ced4da;
    color: #495057; }
    .menu_states .nav-menu .menu-link[href*="#"]:hover {
      background-color: #ced4da;
      color: #495057;
      cursor: default; }

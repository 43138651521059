a {
	&:link {
		color: $color-link;
	}
	&:visited {
		color: $color-link-visited;
	}
	&:hover,
	&:focus {
		text-decoration: none;
		color: $color-link-hover;
	}
	&:focus {
		outline: none;
		box-shadow: 0 0 1px 3px rgba(0,0,0,0.2);
	}
}

a.phone {
	color: theme(primary, base);
	&:hover {
		color: theme(primary, base);
		cursor: default;
	}
}

